import type { CustomStoreImplementationOptions, KaElementMap, MergeCustomStore, StoreIdentifier } from 'kapix-components-vue3'
import { defineStore } from 'pinia'
import type { WritableComputedRef } from 'vue'
import { NOOP, initAliveStoreIds, kapixContext, navigateTo, remove } from 'kapix-components-vue3'
import headerBtnSonProfilOuConnexionComponentStoreCustom from './store.custom'

const storeName = 'headerBtnSonProfilOuConnexionComponent'
const customImplement: CustomStoreImplementationOptions = headerBtnSonProfilOuConnexionComponentStoreCustom.options
const aliveStoreIds = initAliveStoreIds()

function headerBtnSonProfilOuConnexionComponentFactory (storeId?: Nullable<StoreIdentifier>) {
  return defineStore(storeId == null ? storeName : `${storeName}/${storeId}`, {
    state: () => {
      return {
        $aliveStoreIds: aliveStoreIds,
        $subStoreIds: 1,
        $storeId: storeId,
        $emit: (() => {}) as (propName: any, value: any) => void,
        $elements: {} as KaElementMap,
        $router: kapixContext.isClient ? kapixContext.$router : undefined,
        $route: kapixContext.isClient ? kapixContext.$route : undefined,
        $parentPage: NOOP,
        $parentComponent: NOOP,
        // Constants,
        $constants: {
          containerHidden: false,
          containerHidden1: null,
          containerHidden2: undefined
        },
        // Data,
        $data: {
          isLogged: undefined as Nullable<boolean>,
          urlImage: undefined as Nullable<string>,
          dropDown: undefined as Nullable<boolean>
        },
        ...(customImplement.state && customImplement.state())
      }
    },
    getters: {
      /* @ts-ignore: to allow override in your custom file */
      $isLogged (): WritableComputedRef<Nullable<boolean>> {
        return computed({
          get: () => this.$data.isLogged,
          set: (value) => {
            this.$data.isLogged = value
            this.$emit('update:isLogged', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $urlImage (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.urlImage,
          set: (value) => {
            this.$data.urlImage = value
            this.$emit('update:urlImage', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $dropDown (): WritableComputedRef<Nullable<boolean>> {
        return computed({
          get: () => this.$data.dropDown,
          set: (value) => {
            this.$data.dropDown = value
            this.$emit('update:dropDown', value)
          }
        })
      },
      ...customImplement.getters
    },
    actions: {
      /* @ts-ignore: to allow override in your custom file */
      async connexionClick () {
        await navigateTo({ name: 'Connexion' })
      },
      /* @ts-ignore: to allow override in your custom file */
      container1Click () {
        this.$elements['container-2'].style = { hidden: this.$constants.containerHidden }
      },
      /* @ts-ignore: to allow override in your custom file */
      container1ClickWhenSelected () {
        this.$elements['container-2'].style = { hidden: this.$constants.containerHidden1 }
      },
      /* @ts-ignore: to allow override in your custom file */
      containerClickOnXs () { },
      /* @ts-ignore: to allow override in your custom file */
      async profilClick () {
        this.$elements['container-2'].style = { hidden: this.$constants.containerHidden1 }
        await navigateTo({ name: 'Connexion' })
      },
      /* @ts-ignore: to allow override in your custom file */
      text1Click () {
        /* @ts-ignore: custom code */
        this.customDisconnect()
        this.$elements['container-2'].style = { hidden: this.$constants.containerHidden1 }
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        return storeId != null ? getStoreInstance(storeId) : this
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstances () {
        return aliveStoreIds.map(storeId => this.getStoreInstance(storeId))
      },
      /* @ts-ignore: to allow override in your custom file */
      newStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        const newStoreId = storeId || this.$subStoreIds++
        if (aliveStoreIds.includes(newStoreId)) {
          throw new Error(`Store with id ${storeId} already exists`)
        }
        aliveStoreIds.push(newStoreId)
        return getStoreInstance(newStoreId)
      },
      ...customImplement.actions,
      /* @ts-ignore: to allow override in your custom file */
      beforeMount () {
        customImplement.actions?.beforeMount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      mounted () {
        customImplement.actions?.mounted?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      async serverPrefetch () {
        await customImplement.actions?.serverPrefetch?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      beforeUnmount () {
        customImplement.actions?.beforeUnmount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      unmounted () {
        if (this.$storeId != null) {
          this.$dispose()
          remove(aliveStoreIds, this.$storeId)
        }
        customImplement.actions?.unmounted?.call(this)
      }
    }
  })
}

function getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
  return headerBtnSonProfilOuConnexionComponentFactory(storeId)()
}

export const headerBtnSonProfilOuConnexionComponentRaw = headerBtnSonProfilOuConnexionComponentFactory()
export const headerBtnSonProfilOuConnexionComponent = () => headerBtnSonProfilOuConnexionComponentRaw() as MergeCustomStore<typeof headerBtnSonProfilOuConnexionComponentStoreCustom.instance>
