//* * this file should be modified only by you */
// import type { WritableComputedRef } from 'vue'
import type { CustomStoreImplementation, CustomStoreImplementationInstance } from 'kapix-components-vue3'
import type { StateTree, _GettersTree } from 'pinia'
import { useToast } from 'kapix-components-vue3'
import type { headerBtnSonProfilOuConnexionComponentRaw } from './store'
import { useAuthStore } from '~/stores/auth'

type ProfilComponentStore = ReturnType<typeof headerBtnSonProfilOuConnexionComponentRaw>
function defineCustomStoreImplementation<S extends StateTree = {}, G extends _GettersTree<S> = {}, A = {}> (options: CustomStoreImplementation<ProfilComponentStore, S, G, A>) {
  return {
    instance: options as CustomStoreImplementationInstance<ProfilComponentStore, S, G, A>,
    options
  }
}

export default defineCustomStoreImplementation({
  state: () => {
    return {
      // custom state here
    }
  },
  getters: {
    // // ** EXAMPLE ** //
    // myCustomGetter (): WritableComputedRef<Nullable<string>> {
    //   return computed({
    //     get: () => this.$title,
    //     set: (value) => {
    //       this.$title = value
    //     }
    //   })
    // }
  },
  actions: {
    async customDisconnect () {
      const authStore = useAuthStore()
      const toast = useToast()
      const { error } = await authStore.logout()
      if (error) toast.error(`Erreur de déconnexion : ${error.message}`)
    },

    beforeMount () {
    },
    async mounted () {
      // // ** EXAMPLE ** //
      // // custom action like fetching data
      const authStore = useAuthStore()
      watch(() => authStore.isUserLoggedIn, () => {
        this.$isLogged.value = authStore.isUserLoggedIn
      }, { immediate: true })
    },
    beforeUnmount () {
    },
    unmounted () {
    }
  }
})
